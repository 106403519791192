import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./PatientResources.css";
import { EUBanner } from "../../../Banner/Banner.js";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../consts/cdn.js";
import {
  slide1,
  PatientResourcesSlidesEU,
} from "../../../Carousel/CarouselData.js";
import Carousel from "../../../Carousel/Carousel.js";

//Images
const BannerDesktop = cdn.images + "Patients/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "Patients/Banner-Mobile.jpg";
const Image1a = cdn.images + "Patients/Image1a.jpg";
const Image1b = cdn.images + "Patients/Image1b.jpg";
const Image1c = cdn.images + "Patients/Image1c.jpg";
const Image1d = cdn.images + "Patients/Image1d.jpg";

const CircleBackgroundDesktopLeft =
  cdn.images + "Patients/CircleBackground-Left-Desktop.png";
const CircleBackgroundMobileLeft =
  cdn.images + "Patients/CircleBackground-Left-Mobile.png";

function PatientResources() {
  return (
    <div className="patients">
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        boldTitle1="A DIFFERENT"
        boldTitle2="HYSTERECTOMY"
        boldTitle3="OPTION"
        subtitle="Introducing vNOTES to patients as another approach to hysterectomy."
        color="#EA6100"
      />
      <div className="content">
        <div className="hcphome__extrapadding">
          <h1 className="header-primary header-primary--orange header-primary--comfortaa">
            What is{" "}
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES?
          </h1>
          <p className="paragraph">
            If a hysterectomy has been recommended to treat a condition &ndash;
            such as pelvic pain, heavy periods or a prolapsed uterus &ndash;{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            (vaginal natural orifice transluminal endoscopic surgery) may be an
            option. In a{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            procedure, specialized instruments are inserted through the vagina
            instead of making incisions in the abdomen.
          </p>
        </div>
        <div className="patients__img-container patients__img-container--1">
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1a}
              alt="Open Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Open Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1b}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Laparoscopic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1d}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Robotic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1c}
              alt="vNOTES"
              loading="lazy"
            />
            <div className="patients__img-caption" style={{ color: "#4B759D" }}>
              vNOTES
            </div>
          </div>
        </div>

        <br className="br__desktop-only" />

        <div className="hcphome__extrapadding">
          <p className="paragraph">
            In addition to hysterectomies,{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            can be used for other gynecologic procedures:
          </p>

          <ul className="patients__list">
            <li>
              <p className="paragraph">Fallopian tube or ovarian procedures</p>
            </li>
            <li>
              <p className="paragraph">Permanent sterilization</p>
            </li>
            <li>
              <p className="paragraph">Pelvic organ prolapse repair</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="patients__section" style={{ background: "#fafafa" }}>
        <div className="patients__half-text hcphome__extrapadding">
          <h1
            className="header-primary--orange header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            vNOTES PROVIDES THE
            <br />
            FOLLOWING BENEFITS
            <br />
            COMPARED TO
            <br />
            LAPAROSCOPIC SURGERY
            <sup
              className="ref__style patients__header-ref"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. <i>BJOG</i>. 2019;126(1):105-113.<br/>doi:10.1111/1471-0528.15504"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>
            :
          </h1>
        </div>
        <div className="patients__half-img hcphome__extrapadding">
          <Carousel data={slide1} carousel_name="benefits-carousel" autoplay />
        </div>
      </div>

      <div className="vnotes-expect-container">
        <div
          className="content hcphome__right-content vnotes-expect-content"
          style={{
            position: "absolute",
            textAlign: "right",
          }}
        >
          <h1
            className="header-primary header-primary--orange header-primary--comfortaa vnotes-expect-header"
            style={{ margin: "0px" }}
          >
            WHAT PATIENTS CAN <br />
            EXPECT WITH <br className="br__mobile-only" />
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES.{" "}
          </h1>
          <p class="vnotes-expect-subheader">
            vNOTES may not be suitable for all patients. It is important to
            inform <br className="vnotes-expect-desktop-break" /> patients that
            vNOTES, like all surgeries, does entail risks, including{" "}
            <br className="vnotes-expect-desktop-break" /> the potential for
            conversion to traditional methods.
          </p>
        </div>
        <img
          className="banner__img banner__img--desktop patients__banner--desktop"
          src={CircleBackgroundDesktopLeft}
          alt=""
          style={{ aspectRatio: "auto" }}
        />
        <img
          className="banner__img banner__img--mobile"
          src={CircleBackgroundMobileLeft}
          alt=""
          style={{ aspectRatio: "auto" }}
        />
      </div>

      <div
        className="content content--carousel"
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          backgroundColor: "rgb(250, 250, 250)",
        }}
      >
        <Carousel
          data={PatientResourcesSlidesEU}
          carousel_name="procedure-carousel"
          singleImage
        />
      </div>

      <ReactTooltip />
    </div>
  );
}

export default PatientResources;
