import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./Terms.css";
import { USBanner } from "../../../Banner/Banner";
import { cdn } from "../../../../consts/cdn";

//images
const BannerDesktop = cdn.images + "Terms/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Terms/Banner-Mobile.jpg";

function Terms() {
  return (
    <div className="terms">
      <USBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="LEGAL NOTICE"
        color="#365B7E"
      />
      <div className="content">
        <div className="terms__disclaimer-container">
          <p className="paragraph terms__paragraph">
            These terms of use ("Terms of Use") were last updated on September
            18, 2015.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            Our websites, including&nbsp;
            <a className="terms__anchor" href="https://www.appliedmedical.com">
              www.appliedmedical.com
            </a>
            &nbsp;and other Applied Medical websites, online applications,
            blogs, social network sites, or other online or wireless sites,
            accessed via computer, mobile device, or other technology ("Applied
            Medical Sites" or "Sites") are published and maintained by Applied
            Medical Resources Corporation subsidiaries, affiliates, and/or
            related entities (collectively, "Applied Medical"). When you enter
            any of the Sites, that site may have its own terms and conditions of
            use which are specific to it. When you access, browse, or use the
            Sites, you accept, without limitation or qualification, the terms
            and conditions set forth below and any additional terms and
            conditions of use set forth on any of the Sites. The terms "we" and
            "us" refer to Applied Medical, and the terms "you" or "your" refers
            to the user or viewer of the Sites.
          </p>
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">
            Your Right to Use the Sites and Its Content
          </h1>
          <p className="paragraph terms__paragraph">
            The Sites are only for your personal use. You may not distribute,
            exchange, modify, sell, or transmit anything you copy from the
            Sites, including, but not limited to, any text, images, audio, and
            video for any business, commercial or public purpose. You must
            comply with all applicable laws, statutes, regulations, and treaties
            in your use and access of the Sites. As long as you comply with the
            Terms of Use, Applied Medical grants you a non-exclusive,
            non-transferable, limited right to enter, display, and use the
            Sites.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            You agree not to do any of the following: (i) interrupt or attempt
            to interrupt the operation of the Sites in any way, (ii) create
            derivative works of any Content, (iii) use any Content in a manner
            that may infringe or misappropriate the intellectual property rights
            of us or a third party, (iv) remove, change, or obscure any
            copyright or other proprietary notice contained on the Sites, (v)
            remove, decompile, disassemble, or reverse engineer any Site
            software or use any network monitoring or discovery software to
            determine architecture, and (vi) export the Sites, any portion
            thereof, or any technology contained therein, in violation of the
            export control laws of the United States. Applied Medical reserves
            the right to investigate complaints or reported violations of the
            Terms of Use and take any action it deems appropriate, including,
            but not limited to, reporting activity to law enforcement and
            regulators, and disclosing information necessary or appropriate to
            such entities relating to you and your use of the Sites.
          </p>
          <br />
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">
            1. Intellectual Property and Use of Content
          </h1>
          <p className="paragraph terms__paragraph">
            <b>Content.</b> The Sites and all of the content included thereon,
            including, but not limited to, any information, data, text,
            organization, graphics, logos, button icons, images, pictures,
            audio, video, and software, and compilation, translation or
            conversion thereof (the "Content"), is the property of Applied
            Medical, our strategic partners or suppliers, or content providers
            and is protected by copyright laws and international conventions.
            The Content is a collective work protected by United States
            copyright laws. Any other use of this Content, including
            modification, transmission, presentation, distribution, or
            republication, except as expressly granted in the Terms of Use, is
            prohibited without the prior written consent of Applied Medical. You
            agree to follow all instructions on the Sites limiting the way you
            may use Content.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Trademarks.</b> All trademarks, trade names, logos, product names, and
            service marks of Applied Medical found on the Sites, whether
            registered or unregistered, are the property of Applied Medical and
            are protected by United States trademark laws. All other trademarks,
            trade names, logos, product names, and service marks contained on
            the Sites are the property of their respective owners. Applied
            Medical trademarks can be found here. Except as expressly granted in
            the Terms of Use or with Applied Medical’s specific written consent,
            any use of these proprietary materials is prohibited. If you
            download any Content, you may not remove any copyright or trademark
            notices or other notices that go with it. Any unauthorized use of
            the Content may violate copyright laws, trademark laws, the laws of
            privacy and publicity, and civil and criminal statutes.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Patents.</b> All patents issued to and patent applications filed by
            Applied Medical are the property of Applied Medical and are
            protected by United States and foreign patent laws. Any use of these
            patented or patentable assets is prohibited without the specific
            prior written consent of Applied Medical.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Limited License.</b> Subject to the Terms of Use, Applied Medical grants
            you a limited, non-exclusive, non-transferable, revocable license to
            access, use, display and, subject to any expressly stated
            restrictions or limitations relating to specific material as may be
            posted on Applied Medical's Sites or otherwise known to you,
            download one copy of the material from the Sites, solely for your
            internal, personal, non-commercial use (“Limited License”). This
            Limited License is non-exclusive and non-transferable. You agree
            that the Limited License may be updated or changed from time to time
            for any reason, and you agree to be bound by any such revisions.
            This Limited License does not include any rights to Applied
            Medical’s patents, patent applications, trade secrets, or similar
            proprietary material.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Termination of the Limited License.</b> If you violate any of the Terms
            of Use, your permission to use the Content of the Sites
            automatically will terminate, and you immediately must destroy any
            copies you have made of any Content. In addition, the Limited
            License may be terminated at any time and for any reason or for no
            reason, without cause or notice. Applied Medical reserves the right
            to revoke the Limited License if you fail to comply with quality
            control measures, which Applied Medical may promulgate and
            communicate to you from time to time.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Injunctive Relief.</b> You agree that in the event of a breach of
            Section 1 of the Terms of Use, Applied Medical shall not have an
            adequate remedy at law, including monetary damages, and that Applied
            Medical shall consequently be entitled to seek any injunctive or
            other form of equitable relief without the posting of a bond, in
            addition to any and all remedies to which Applied Medical shall be
            entitled.
          </p>
          <br />
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">2. Disclaimer</h1>
          <p className="paragraph terms__paragraph">
            <b>General Disclaimers.</b> YOUR USE OF THE SITES IS AT YOUR OWN RISK. ALL
            SERVICES, INFORMATION, PRODUCTS, AND MATERIALS OBTAINED THROUGH THE
            SITES INCLUDING ALL CONTENT, LINKS, AND "COOKIES," ARE PROVIDED ON
            AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT
            PERMISSIBLE BY LAW, APPLIED MEDICAL DISCLAIMS ALL WARRANTIES AND
            CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, WITH REGARD TO THE
            SITES, CONTENT, PRODUCTS, SERVICES, AND INFORMATION CONTAINED ON OR
            MADE AVAILABLE THROUGH THE SITES, INCLUDING, BUT NOT LIMITED TO, THE
            AVAILABILITY OF PRODUCTS OR SERVICES AND THE ACCURACY OF THE
            CONTENT. APPLIED MEDICAL MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND WHATSOEVER THAT THE SITES AND ACCESS TO THE SITES ARE FREE
            OF VIRUSES OR OTHER COMPONENTS THAT MAY INFECT, HARM, OR CAUSE
            DAMAGE TO YOUR COMPUTER EQUIPMENT OR ANY OTHER PROPERTY WHEN YOU
            ACCESS, BROWSE, DOWNLOAD FROM, OR OTHERWISE USE THE SITES.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            APPLIED MEDICAL'S PRODUCTS ARE ONLY SUBJECT TO A SEPARATE WRITTEN
            WARRANTY, IF ANY, ACCOMPANYING THOSE PRODUCTS. APPLIED MEDICAL MAKES
            NO REPRESENTATIONS, WARRANTIES, OR RECOMMENDATIONS WITH RESPECT TO
            ANY TREATMENT OR ACTION WITH RESPECT TO THE APPROPRIATENESS OF ANY
            MEDICAL DEVICES OR PROCEDURES ABOUT WHICH INFORMATION OR CONTENT IS
            PROVIDED WITHIN OR THROUGH THE SITES.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Disclaimer of Medical Liability.</b> APPLIED MEDICAL DOES NOT PROVIDE
            MEDICAL ADVICE. The contents of the Sites are for general
            informational purposes only. The content is not intended to be a
            substitute for professional medical advice, diagnosis, or treatment.
            Reliance on any information provided on the Sites is solely at your
            own risk. Individual medical needs are very different; you should
            not assume that the information on the Sites concerning certain
            courses of treatment or outcomes will apply to you. Rather, you
            should evaluate your medical condition and make treatment decisions
            based upon consultation with your physician.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            The contents of the Sites may be of interest to medical professions
            or other health care providers. Medical professionals and health
            care providers should exercise their own judgment in determining
            whether a particular product or procedure is appropriate for their
            practice or their patients.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Product and Service Availability.</b> Applied Medical frequently updates
            and improves its products. Applied Medical may discontinue or modify
            products at any time. The Sites contain information about products
            that may not be available in all countries. Even if available, the
            governmental approval or market clearance received for a particular
            product in one country may be for a different use or indication, or
            subject to different restrictions, than in another country. Each
            country may have specific laws, regulations, and medical practices
            that govern the communication of information about medical products
            and other products over the internet. Nothing in the Terms of Use
            shall be construed as a solicitation for, or promotion of, any
            product or the use of any product that is not authorized by the laws
            and regulations of the country where you reside or access the Sites.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Product Orders.</b> All orders of products placed by you with Applied
            Medical are subject to acceptance by Applied Medical. Applied
            Medical may reject any order you place and/or to limit the
            quantities on any order. Your order shall not be deemed accepted by
            Applied Medical until shipment of the products ordered. Your order
            submission shall represent your full acceptance of these Terms of
            Use. If you do not accept the Terms of Use, you should not submit an
            order.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Payment Terms.</b> Unless otherwise indicated in writing to you, the
            full purchase price of products sold through the Sites will be
            billed at the time of shipment. Applied Medical will debit cards
            upon shipment. Applied Medical may change these payment terms
            without notice at its sole discretion.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Limitations of Liability.</b> IN NO EVENT SHALL APPLIED MEDICAL OR ANY
            OF ITS AGENTS, THIRD-PARTY CONTENT PROVIDERS, AND SUPPLIERS, OR THE
            RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS BE LIABLE FOR
            ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT, CONSEQUENTIAL, OR
            CONTRIBUTORY DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION,
            DAMAGES FOR PERSONAL INJURY, WRONGFUL DEATH, LOSS OF USE, LOSS OF
            BUSINESS PROFITS, BUSINESS INTERRUPTION, OR LOSS OF DATA), WHETHER
            IN AN ACTION IN WARRANTY, CONTRACT, OR TORT, ARISING OUT OF OR IN
            ANY WAY CONNECTED WITH (i) THE USE OR PERFORMANCE OF THE SITES OR
            ANY PRODUCTS PURCHASED THEREON; (ii) THE DELAY OR INABILITY TO USE
            THIS WEBSITE; (iii) THE PROVISION OF OR FAILURE TO PROVIDE SERVICES,
            OR FOR ANY INFORMATION, SERVICES, AND RELATED GRAPHICS CONTAINED ON
            OR MADE AVAILABLE THROUGH THIS WEBSITE; (iv) THE FAILURE TO PROVIDE
            THE PRODUCTS OR SERVICES; AND/OR (v) DAMAGES CAUSED BY THE ACTS OR
            OMISSIONS OF YOU OR ANY THIRD PARTY. THIS PARAGRAPH SHALL APPLY TO
            THE EXTENT ALLOWED BY APPLICABLE LAW. IN NO EVENT SHALL APPLIED
            MEDICAL'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES
            OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED
            TO, NEGLIGENCE OR OTHERWISE) EXCEED $100.00.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            IN NO EVENT IS APPLIED MEDICAL LIABLE FOR ANY DAMAGES CAUSED BY YOUR
            OR ANY THIRD PARTY'S ACTS OR OMISSIONS. APPLIED MEDICAL IS NOT
            RESPONSIBLE FOR ANY CONTENT DISTRIBUTED BY YOU THROUGH THE SITES OR
            ASSOCIATED SERVICES. APPLIED MEDICAL IS NOT LIABLE FOR ANY CONTENT
            PROVIDED BY THIRD PARTIES OR USERS, INCLUDING ANY DEFAMATORY,
            OFFENSIVE, OR ILLEGAL CONDUCT, AND THE RISK OF INJURY FROM THE
            FOREGOING RESTS ENTIRELY WITH YOU.
          </p>
          <br />
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">3. Indemnity</h1>
          <p className="paragraph terms__paragraph">
            You agree to defend, indemnify, and hold Applied Medical, its
            officers, directors, employees, agents, licensors, licensees,
            suppliers, and affiliates harmless from and against any claims,
            actions, or demands, liabilities, costs, and settlements, including,
            without limitation, legal and accounting fees, resulting from, or
            alleged to result from, your violation of the Terms of Use or the
            representations and warranties therein, or relating to or arising
            from your use of the Sites (including providing content for the
            Sites), and any content, products, or services obtained on or
            through the Sites. You shall cooperate as fully as reasonably
            required in Applied Medical’s defense of any such claim. Applied
            Medical reserves the right, at its own expense, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you and you shall not in any event settle any
            matter without the prior written consent of Applied Medical.
          </p>
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">
            4. Links to and from Other Websites
          </h1>
          <p className="paragraph terms__paragraph">
            Applied Medical's Sites may contain links, references to, or other
            method of access to websites operated by third parties. Applied
            Medical does not, either expressly or implicitly, endorse the
            content on such websites, the third party, or any association it may
            have with third parties. The links, if any, are provided for your
            convenience only. Applied Medical has no control over such web
            sites, and does not review the content of those websites; therefore,
            Applied Medical is not responsible or liable for any content,
            advertising, products, services, or other materials on or available
            from such websites. You acknowledge and agree that Applied Medical
            is not responsible or liable, either directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, advertising, products,
            services, or other material available on or through any such
            website. Applied Medical disclaims all warranties, express or
            implied, as to the accuracy, legality, reliability, or validity of
            any content on any other website.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            Applied Medical encourages you to link to our home page at &nbsp;
            <a className="terms__anchor" href="https://www.appliedmedical.com">
              www.appliedmedical.com
            </a>
            , for non-commercial purposes only and only for the convenience of
            visitors to your own website, provided you notify us in writing to
            &nbsp;
            <a className="terms__anchor" href="mailto:info@appliedmedical.com">
              info@appliedmedical.com
            </a>
            . We may provide you with the HTML linking code needed to create
            such a link. You shall discontinue any links immediately at the
            request of Applied Medical. You do not have permission to link to
            any other portion of our Sites. Deep linking into the various areas
            of our Sites or framing of any of our Sites’ pages without our prior
            written permission is a violation of the Terms of Use. Linking to
            Applied Medical's Sites is subject to the provisions of these Terms
            of Use regarding intellectual property and use of Content. You may
            not make any statement suggesting or implying in connection with any
            link to our Sites that Applied Medical has in any way endorsed,
            approved, or sponsored your own web site, content thereon, or any
            products, services, or other material. In addition, the use of
            robot, spider, other automatic device, or manual process to monitor
            or copy our Sites or Content or for any other unauthorized purpose
            without our prior express written permission is also in violation of
            the Terms of Use.
          </p>
          <br />
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">
            5. Content and User Materials
          </h1>
          <p className="paragraph terms__paragraph">
            <b>Applied Medical’s Right to Change the Terms of Use or Content.</b>
            Applied Medical may amend the Terms of Use at any time, without
            notice. Any changes to the Terms of Use or any terms and conditions
            posted on the Sites apply upon posting without specific notice to
            you. By continuing to use the Sites after changes are posted, you
            are accepting those changes. Applied Medical may add, remove,
            change, permanently or temporarily, any Content posted or services
            provided by the Sites, at any time, without notice and without
            liability.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>User’s Material.</b> The Sites may have areas which allow users to
            upload and/or download materials or otherwise communicate. When
            using any of these areas, you agree not to post, send, transmit,
            upload, or otherwise publish through the Sites, any materials that
            (1) interfere with anyone else's use of the Sites or the privacy of
            any other user; (2) are defamatory, libelous, abusive, illegal,
            indecent, obscene, offensive, threatening, or otherwise unlawful;
            (3) encourage conduct that could be considered a criminal offense or
            otherwise violate the law; (4) violate anyone's copyright or other
            intellectual property right; (5) contain a virus or any other
            harmful material; (6) contain false or misleading statements; or (7)
            contain any third party’s personal information, including, but not
            limited to, first and last name, email address, mailing address, fax
            number, or telephone number.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            From time to time, Applied Medical may monitor or review any content
            posted or submitted to the Sites. However, you understand and agree
            that Applied Medical is not obligated to do so and assumes no
            liability or responsibility arising from the contents of any such
            communications. Applied Medical may change, edit, or remove any user
            material that in its opinion violates the Terms of Use or any other
            Applied Medical policies. Applied Medical will fully cooperate with
            any law enforcement authorities or court order requesting or
            directing Applied Medical to disclose the identity of anyone posting
            such materials.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Applied Medical's Rights to User Supplied Material.</b> Unless otherwise
            specified in writing by Applied Medical, if you send any information
            or materials to the Sites, all such information and materials are,
            and will be treated as, non-confidential and non-proprietary, and
            therefore, you are waiving any claim that any use by Applied Medical
            of such material violates your rights, including privacy,
            intellectual property, publicity and any similar rights.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            Any information or material submitted to the Sites by you may be
            modified, transmitted, presented, distributed, or published, copied,
            licensed, disclosed, sold, transmitted, or used by Applied Medical
            for any purpose, worldwide, in any medium without compensation to
            you. Unless otherwise specified in writing by Applied Medical,
            Applied Medical is free to use, without any compensation to you, any
            intellectual property, ideas, know-how, or techniques disclosed in
            any information or materials provided by you to the Sites for any
            purpose.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Transmissions.</b> Internet transmissions are not 100% private or
            secure. You understand that any material or information you send to
            the Sites may be read or intercepted by others, unless there is a
            written notice that particular material or information is encrypted.
          </p>
          <br />
        </div>
        <div className="terms__disclaimer-container">
          <h1 className="header-primary terms__header">
            6. General Legal Provisions
          </h1>
          <p className="paragraph terms__paragraph">
            <b>International Laws/Users.</b> Applied Medical is based in Rancho Santa
            Margarita, California, USA. Applied Medical makes no claims that the
            Content is appropriate or may be downloaded outside of the United
            States. Access to the Content may not be legal by certain persons or
            in certain countries. If you access Applied Medical's Sites from
            outside the United States, you do so at your own risk and are
            responsible for compliance with the laws of your jurisdiction.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Choice of Law and Forum.</b> You expressly agree that exclusive
            jurisdiction for any dispute with Applied Medical, or in any way
            relating to your use of Applied Medical's Sites, resides in the
            courts of the State of California, and you further agree and
            expressly consent to the exercise of personal jurisdiction in the
            courts of the State of California in connection with any such
            dispute including any claim involving Applied Medical or its
            affiliates, subsidiaries, employees, contractors, officers,
            directors, telecommunication providers, and content providers. The
            Terms of Use shall be treated as though it were executed and
            performed in Orange County, California, and shall be governed by the
            laws of the State of California, without respect to its conflict of
            laws principles.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Entire Agreement.</b> Except as expressly provided in a particular
            "legal notice" on Applied Medical's Sites, the Terms of Use, the
            then-current Privacy Policy, and any other express terms provided by
            Applied Medical on the Sites constitute the entire agreement between
            you and Applied Medical with respect to the use of Applied Medical's
            Sites and Content. You also may be subject to additional terms and
            conditions that may apply when you use Applied Medical products,
            affiliate services, third-party content, or third-party software.
            The language in the Terms of Use shall be interpreted as to its fair
            meaning and not be strictly construed for or against any party. To
            the extent that anything in the Contents is in conflict or is
            inconsistent with the Terms of Use, the Terms of Use shall take
            precedence.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            The sale, purchase, and use of any of Applied Medical products are
            subject to the terms and conditions, warranties, warranty
            disclaimers, and limitations of liability set forth in any separate
            written terms and conditions, user manuals, instructions for use,
            product catalogues, or similar documentation provided by Applied
            Medical.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            The Terms of Use and all incorporated agreements may be assigned by
            Applied Medical in the event of an acquisition, sale, merger or
            other disposition.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            <b>Disputes.</b> Any cause of action you may have with respect to your use
            of the Sites must be commenced within one (1) year after the claim
            or cause of action arises, or be forever waived and barred. If for
            any reason a court of competent jurisdiction finds any provision of
            these Terms of Use, or portion thereof, to be invalid or
            unenforceable, that provision shall be enforced to the maximum
            extent permissible so as to effect the intent of this agreement, and
            the remainder of these Terms of Use shall continue in full force and
            effect. Applied Medical’s failure or delay in enforcing its rights
            under the Terms of Use shall not be deemed a waiver of such rights.
          </p>
          <br />
          <p className="paragraph terms__paragraph">
            Thank you for your cooperation. We hope you find Applied Medical's
            Sites helpful and convenient to use. Questions or comments regarding
            the Sites should be directed by email to{" "}
            <a className="terms__anchor" href="mailto:info@appliedmedical.com">
              info@appliedmedical.com
            </a>
            , by telephone to (949) 713-8000, or by mail to Applied Medical
            Resources Corporation, 22872 Avenida Empresa, Rancho Santa
            Margarita, California 92688.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Terms;
