import React, { useState, useEffect, useRef } from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import Card from "../../../../Card/Card";
import "./FindSurgeon.css";
import { USBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../../consts/cdn";
import { api } from "../../../../../consts/api";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import { GeoAltFill } from "react-bootstrap-icons";
import SkeletonDoctor from "../../../../../skeletons/FindASurgeon/SkeletonDoctor";
import { sendEventGTM } from "../../../../../utilities/GoogleAnalyticsEvents";

//Images
const BannerDesktop = cdn.images + "FindASurgeon/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "FindASurgeon/Banner-Mobile.jpg";

function FindSurgeon() {
  const [allDoctors, setAllDoctors] = useState();
  const [doctors, setDoctors] = useState();
  const [noDoctorsNearby, setNoDoctorsNearby] = useState(false);
  const errorMsg =
    "There are no surgeons available. Please try a different location.";
  const cityRef = useRef();
  const stateRef = useRef();
  const locationRef = useRef();

  async function getAllDoctors() {
    try {
      const allDoctors = await fetch(api + "/doctor").then((response) =>
        response.json()
      );

      setAllDoctors(allDoctors);
      setDoctors(allDoctors);
      setNoDoctorsNearby(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function getNearbyDoctors(lat, lon) {
    try {
      const newDoctors = await fetch(
        api + `/Doctor?latitude=${lat}&longitude=${lon}`
      ).then((response) => response.json());

      setDoctors(newDoctors);
      setNoDoctorsNearby(false);
    } catch (error) {
      setDoctors([]);
      setNoDoctorsNearby(true);
    }
  }

  useEffect(() => {
    getAllDoctors();
  }, []);

  function onPlaceSelect(value) {
    if (Object.is(value, null)) {
      setDoctors(allDoctors);
      setNoDoctorsNearby(false);
    } else {
      cityRef.current = value.properties.city;
      stateRef.current = value.properties.state;
      locationRef.current = `${value.properties.city}, ${value.properties.state}`;

      sendEventGTM("FaS-search", {
        fas_city: cityRef.current,
        fas_state: stateRef.current,
        fas_location: locationRef.current,
      });

      getNearbyDoctors(value.properties.lat, value.properties.lon);
    }
  }

  function postProcessHook(feature) {
    return feature.properties.address_line1;
    // return feature.properties.address_line1.replace("(undefined)", "");
    // return `${feature.properties.city}, ${feature.properties.state_code} ${feature.properties.postcode}`;
  }

  // function onSuggestionChange(value) {
  //   console.log(value);
  // }

  return (
    <div className="findSurgeon">
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <USBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        text1="FIND A vNOTES"
        bold2="SURGEON"
        color="#365B7E"
      />
      <div className="content">
        <div className="findSurgeon__div-discretion">
          <p className="paragraph findSurgeon__paragraph">
            This resource is provided to assist you in finding a vNOTES surgeon
            in the United States. It is not a complete listing of all surgeons
            who perform vNOTES. It's important to talk to your doctor to
            determine if vNOTES or another procedure is recommended to treat
            your specific condition.
          </p>
          <br />
          <p className="paragraph findSurgeon__paragraph">
            If you are a vNOTES surgeon and are interested in being included in
            this directory, please contact{" "}
            <a
              className="findSurgeon__anchor-email"
              href="mailto:vNOTES@appliedmedical.com"
            >
              vNOTES@appliedmedical.com
            </a>{" "}
            for more information.
          </p>
        </div>

        <div className="search-div">
          <div className="search-container">
            <span className="findSurgeon__span-icon-1">
              <GeoAltFill color="#faa21e" className="map-pin-icon" />
            </span>

            <GeoapifyContext apiKey="6a676675bba74157bb9deac15edfeec9">
              <GeoapifyGeocoderAutocomplete
                placeholder={"Enter a ZIP Code or City"}
                type={"postcode"}
                filterByCountryCode={["us"]}
                placeSelect={onPlaceSelect}
                postprocessHook={postProcessHook}
              ></GeoapifyGeocoderAutocomplete>
            </GeoapifyContext>
          </div>
          <p className="error">{noDoctorsNearby ? errorMsg : null}</p>
        </div>

        <div className="findSurgeon__div-row">
          {doctors
            ? doctors.map((doctor) => (
                <Card
                  key={doctor.id}
                  img={cdn.images + doctor.profileImage}
                  firstName={doctor.firstName}
                  middleInitial={doctor.middleInitial}
                  lastName={doctor.lastName}
                  title={doctor.title}
                  location={doctor.location}
                  location2={doctor.location2}
                  address1={doctor.address1}
                  additional={doctor.additional}
                  city={doctor.city}
                  state={doctor.state}
                  zip={doctor.zip}
                  phone={doctor.phone}
                  href={doctor.website}
                  distance={doctor.distance}
                />
              ))
            : Array.from(Array(10).keys()).map((n) => (
                <SkeletonDoctor key={n}></SkeletonDoctor>
              ))}
        </div>
      </div>
    </div>
  );
}

export default FindSurgeon;
